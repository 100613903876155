import { FaIcon } from '@web/atomic';
import React, { useEffect, useState } from 'react';
import { BackButtonStyled } from './back-button.component.style';
import { hasWindow } from '@web/utils/platform';
import SessionHistory from '@lp-src/utils/browser/session-history';

interface BackButtonProps {
  onClick?: () => void;
  hideLastRouteName?: boolean;
  forceShow?: boolean;
}

export const BackButton: React.FunctionComponent<BackButtonProps> = (props) => {
  const [isFirstRoute, setFirstRoute] = useState(true);
  const [lastRouteName, setLastRouteName] = useState('');

  useEffect(() => {
    setFirstRoute(SessionHistory.isFirstRoute());
    setLastRouteName(SessionHistory.getLastRouteName());
  });

  if (isFirstRoute && !props.forceShow) {
    return null;
  }

  const handleBackClick = () => {
    if (props.onClick) {
      props.onClick();
    } else if (hasWindow()) {
      window.history.back();
    }
  };

  return (
    <BackButtonStyled type="button" onClick={handleBackClick}>
      <FaIcon.Back /> &nbsp; Voltar {lastRouteName && !props.hideLastRouteName ? `para "${lastRouteName}"` : ''}
    </BackButtonStyled>
  );
};
