import { Col, Grid, Row } from '@web/atomic';
import { BackButton } from '@lp-root/src/components/atm.back-button/back-button.component';
import LargeSeparatorRow from '@lp-root/src/components/atm.large-separator-row/large-separator-row.component';
import { SEO } from '@lp-root/src/components/legacy/mol.seo/seo.component';
import IndexLayout from '@lp-root/src/components/org.layout/layout.component';
import { Query } from '@lp-root/src/data/graphql/local-graphql-types';
import { PageProps } from '@lp-src/utils/local-types';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import * as React from 'react';
import { brandName } from '../constants';
import AboutContentRow from './components/about-content.component';

export interface AboutDetailTemplateProps {
  id: string;
  publishDate: Date;
}

const AboutDetailTemplate: React.FunctionComponent<PageProps<AboutDetailTemplateProps, Query>> = (props) => {
  const data = props.data.markdownRemark;

  return (
    <IndexLayout {...props}>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: data.frontmatter.title,
          image: data.frontmatter.img,
          imageAlt: data.frontmatter.imgAlt,
          description: data.frontmatter.description,
          article: {
            author: [brandName],
            section: 'Sobre Vigilantes do Sono',
            tag: [],
          },
        }}
      />

      <Grid>
        <Row mb mt center="xs">
          <Col xs={12} md={7}>
            <BackButton />
          </Col>
        </Row>

        <AboutContentRow data={data} publishDate={new Date(props.pageContext.publishDate)} />

        <Row center="xs">
          <Col xs={12} md={7}>
            <BackButton />
          </Col>
        </Row>

        <LargeSeparatorRow />
      </Grid>
    </IndexLayout>
  );
};

export default AboutDetailTemplate;

export const query = graphql`
  query AboutDetailBySlug($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    markdownRemark(id: { eq: $id }) {
      id
      timeToRead
      html
      headings {
        depth
        value
      }
      frontmatter {
        date
        path
        title
        description
      }
    }
  }
`;
