import { scrollToId } from '@web/utils/scroll';
import { FaIcon, HeaderHeight } from '@web/atomic';
import { isDesktopScreenSize } from '@web/utils/browser-detection';
import * as React from 'react';
import { TableOfContentsItemStyled, TableOfContentsStyled } from './table-of-contents.component.style';

export interface ScrollInfo {
  id: string;
  text: string;
  tag?: string;
}

export interface TableOfContentsProps {
  scrollInfo: ScrollInfo[];

  /**
   * id of its container (the DOM id).
   * it may be useful to set the scrollable id if you need, for instance, to scroll inside it
   * @see scrollToId
   */
  containerId?: string;
}

export const TableOfContents: React.FunctionComponent<TableOfContentsProps> = (props) => {
  return (
    <TableOfContentsStyled>
      {props.scrollInfo.map((info) => {
        const handleClick = () => {
          const headerHeight = isDesktopScreenSize() ? HeaderHeight.Desk : HeaderHeight.Mobile;
          scrollToId(info.id, headerHeight, props.containerId);
        };
        return (
          <TableOfContentsItemStyled key={info.id} onClick={handleClick}>
            {info.text} &nbsp; <FaIcon.ArrowRight />
          </TableOfContentsItemStyled>
        );
      })}
    </TableOfContentsStyled>
  );
};
