import * as React from 'react';
import { Col, Divisor, FeaturedH2, H1, Row, Separator, Body } from '@web/atomic';
import { TableOfContents, TableOfContentsProps } from '@lp-root/src/components/mol.table-of-contents/table-of-contents.component';
import { MarkdownRemark } from '@lp-root/src/data/graphql/local-graphql-types';
import { diactriclessKebabCase } from '@global/utils/string/case';
import { MainContentStyled } from './about-content.styled';
import { parseDateToDDMMYYYY } from '@global/utils/date';

interface IAboutContentProps {
  data: MarkdownRemark;
  publishDate: Date;
  /**
   * it may be useful to set the scrollable id if you need, for instance, to scroll inside it
   * @see scrollToId
   */
  scrollableContainerId?: string;
}

const AboutContentRow: React.FunctionComponent<IAboutContentProps> = (props) => {
  const data = props.data;
  const publishDate = parseDateToDDMMYYYY(props.publishDate);
  const html = data.html
    // @see files inside packages/app.landing-page/_cms/about to see where this
    // replace operation will happen
    .replace('${publishDate}', publishDate);
  const tableOfContents: TableOfContentsProps = {
    containerId: props.scrollableContainerId,
    scrollInfo: data.headings
      .filter((question) => question.depth <= 3)
      .map((question) => ({
        id: diactriclessKebabCase(question.value).replace(/[().,?]/g, ''),
        text: question.value,
      })),
  };
  const showTableOfContents = tableOfContents.scrollInfo.length > 0;
  return (
    <>
      <Row center="xs">
        <Col xs={12} md={7}>
          <H1>{data.frontmatter.title}</H1>
          {showTableOfContents && (
            <Body>
              Nosso objetivo é melhorar a sua vida, por isso deixamos este documento o mais simples para você consultar. Você pode navegar
              diretamente no tema de maior interesse, mas caso ainda tenha dúvidas, nossos canais de atendimento estão à sua disposição.
            </Body>
          )}
          <Separator />
          <Divisor />
        </Col>
      </Row>

      {showTableOfContents && (
        <Row center="xs">
          <Col xs={12} md={7}>
            <FeaturedH2> Tópicos </FeaturedH2>
            <TableOfContents {...tableOfContents} />
          </Col>
        </Row>
      )}

      <Row mb center="xs">
        <Col xs={12} md={7}>
          {showTableOfContents ? <FeaturedH2> Conteúdo </FeaturedH2> : <Separator />}
          <MainContentStyled dangerouslySetInnerHTML={{ __html: html }} />
          <Separator />
        </Col>
      </Row>
    </>
  );
};

export default AboutContentRow;
