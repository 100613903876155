import { GrayColor, LightColor, FontSize } from '@web/atomic';
import styled from 'styled-components';

export const BackButtonStyled = styled.button`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  border: none;

  font-size: ${FontSize.Small};
  color: ${(props) => (props.theme.name === 'dark' ? GrayColor.White : LightColor.Primary)};

  text-decoration: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
`;
