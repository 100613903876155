import styled from 'styled-components';
import { H4Style, GrayColor, LightColor, Spacing } from '@web/atomic';

export const TableOfContentsStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const TableOfContentsItemStyled = styled.li`
  ${H4Style}
  color: ${GrayColor.GrayXDark};
  padding: ${Spacing.XSmall} 0;
  cursor: pointer;
`;
